import axios from 'axios'
import * as constants from '../../Constants'

class RevenueService {
  static AuthenticatedApi = axios.create({
    baseURL: constants.apiConfig.resourceUri,
    headers: {
      "Content-Type": "application/json",
    },
  });

  static AuthenticatedApi_download = axios.create({
    baseURL: constants.apiConfig.resourceUri,
    responseType: 'blob',

  });


  static GetMasterRevenueData(token, _params) {
    return this.AuthenticatedApi.get("/Revenue/GetMasterRevenueData/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetRevenueDashboard(token, _params) {
    return this.AuthenticatedApi.post('/Revenue/GetRevenueDashboard/',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }


  static GetRevenueDashboardAllIndustries(token, _params) {
    return this.AuthenticatedApi.post('/Revenue/GetRevenueDashboardAllIndustries/',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetRevenueAllGroupAllIndustries(token, _params) {
    return this.AuthenticatedApi.post('/Revenue/GetRevenueAllGroupAllIndustries/',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetSalesList(token, _params) {
    return this.AuthenticatedApi.post('/Revenue/GetSalesList/',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetRevenueDashboardSP(token, _params) {
    return this.AuthenticatedApi.post('/Revenue/GetRevenueDashboardSP/',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static AddBusinessInsight(token, _params) {
    return this.AuthenticatedApi.post('/Revenue/AddBusinessInsight/',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static UpdateBusinessInsight(token, _params) {
    return this.AuthenticatedApi.post('/Revenue/UpdateBusinessInsight/',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static DeleteBusinessInsight(token, _params) {
    return this.AuthenticatedApi.delete("/Revenue/DeleteBusinessInsight/", {
      params: _params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }
  static DownloadRevenueExcel(token, _params) {
    return this.AuthenticatedApi_download.post('/Revenue/DownloadRevenueExcel',
      _params, {
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }


  // Initial load: GET request for unfiltered data
  static GetMicrosoftBillingGridData(token) {
    return this.AuthenticatedApi.get('/Revenue/GetMicrosoftBillingGridData', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  // Filtered data: POST request for filtered data
  static GetMicrosoftBillingGridDataFiltered(token, filterOptions) {
    console.log('Filter options sent to server:', filterOptions); // Add this line
    return this.AuthenticatedApi.post('/Revenue/GetMicrosoftBillingGridDataFiltered', filterOptions, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static DownloadMicrosoftBillingCsv(token, filterOptions) {
    return this.AuthenticatedApi.post('/Revenue/DownloadMicrosoftBillingGridDataCsv', filterOptions, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      responseType: 'blob' // Important for downloading binary data
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response.data;
      });
  }

  static GetFilterOptions(token) {
    return this.AuthenticatedApi.get('/Revenue/GetFilterOptions', {
      headers: { Authorization: 'Bearer ' + token }
    })
      .then(response => {
        if (response && response.status === 200) {
          const data = response.data;
          console.log("Filter options data:", data);
          return {
            Product: data.Product?.map(item => ({ key: item.id, text: item.name })) || [],
            Instance: data.Instance?.map(item => ({ key: item.id, text: item.name })) || [],
            Platform: data.Platform?.map(item => ({ key: item.id, text: item.name })) || [],
            Region: data.Region?.map(item => ({ key: item.id, text: item.name })) || []
          };
        }
        return {};
      })
      .catch(error => {
        console.error('Error fetching filter options:', error);
        return {};
      });
  }
}

export default RevenueService;