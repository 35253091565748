import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Popup, Flex, Text, Datepicker, Checkbox } from '@fluentui/react-northstar';
import { FilterIcon } from '@fluentui/react-northstar';
import RevenueService from '../../../services/revenueservice';
import '../microsoftbilling/microsoftbilling.scss';

const useMultiFilterDialog = ({ handleFilterUpdate, elegibleFilters, acquireToken, resetGridData }) => {
    const [selectedFilters, setSelectedFilters] = useState({
        Product: [],
        Instance: [],
        Platform: [],
        Region: [],
        startDate: '01-01-2024',
        endDate: '01-01-2025',
        Active: false,
    });
    const [tempFilters, setTempFilters] = useState({
        Product: [],
        Instance: [],
        Platform: [],
        Region: [],
        startDate: '01-01-2024',
        endDate: '01-01-2025',
        Active: false,
    });
    const [filterOptions, setFilterOptions] = useState({
        Product: [],
        Instance: [],
        Platform: [],
        Region: [],
        startDate: '01-01-2024',
        endDate: '01-01-2025',
    });



    const [products, setProducts] = useState([]);
    const [instance, setInstance] = useState([]);
    const [platform, setPlatform] = useState([]);
    const [region, setRegion] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);


    useEffect(() => {
        acquireToken((token) => {
            setLoading(true);
            RevenueService.GetFilterOptions(token)
                .then(response => {
                    if (response) {
                        const mappedOptions = {
                            Product: response.Product.map(item => ({ key: item.key, text: item.text })),
                            Instance: response.Instance.map(item => ({ key: item.key, text: item.text })),
                            Platform: response.Platform.map(item => ({ key: item.key, text: item.text })),
                            Region: response.Region.map(item => ({ key: item.key, text: item.text }))
                        };
                        setProducts(response.Product.map(item => item.text));
                        setInstance(response.Instance.map(item => item.text));
                        setPlatform(response.Platform.map(item => item.text));
                        setRegion(response.Region.map(item => item.text));

                        setFilterOptions(mappedOptions);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching filter options:', error);
                    setLoading(false);
                });
        });
    }, [acquireToken]);

    /* const handleDropdownChange = (filterType, event, data) => {
        setTempFilters((prevFilters) => ({
            ...prevFilters,
            [filterType]: data.value
        }));
        if (filterType === "startDate" || filterType === "endDate") {
            setTempFilters((prevFilters) => ({
                ...prevFilters,
                [filterType]: new Date(data.value).toISOString().split('T')[0]
            }));
        }
    }; */

    const handleDropdownChange = (filterType, event, data) => {
        setTempFilters((prevFilters) => ({
            ...prevFilters,
            [filterType]: filterType === "startDate" || filterType === "endDate"
                ? formatDateForSQL(data.value) // Transform date here
                : data.value
        }));
    };




    useEffect(() => {
        if (isOpen) {
            setTempFilters({
                ...selectedFilters,
                startDate: selectedFilters.startDate ? new Date(selectedFilters.startDate) : null,
                endDate: selectedFilters.endDate ? new Date(selectedFilters.endDate) : null,
            });
        }
    }, [isOpen, selectedFilters]);


    const applyFilters = () => {
        const transformedData = {};
        for (const [category, values] of Object.entries(tempFilters)) {
            if (filterOptions[category]) {
                const textToKeyMap = Object.fromEntries(
                    filterOptions[category].map(({ key, text }) => [text, key])
                );
                transformedData[category] = values.map(value => textToKeyMap[value] || value);
            } else {
                transformedData[category] = values;
            }
        }

        // Transform dates to the correct format
        transformedData.StartDate = formatDateForSQL(tempFilters.startDate);
        transformedData.EndDate = formatDateForSQL(tempFilters.endDate);

        console.log('Applying filters with payload:', transformedData); // Add this line

        setSelectedFilters({ ...tempFilters, startDate: transformedData.StartDate, endDate: transformedData.EndDate });
        handleFilterUpdate(transformedData);
        setIsOpen(false);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const formatDateForSQL = (date) => {
        if (!date) return null; // Ensure no date results in null
        // Use toLocaleDateString with a consistent date format
        const d = new Date(date);
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
    };

    const resetFilters = () => {
        console.log('Resetting all filters to default');
        setTempFilters({
            Product: [],
            Instance: [],
            Platform: [],
            Region: [],
            startDate: '01-01-1900',
            endDate: '01-01-2200',
            Active: false
        });
        setSelectedFilters({
            Product: [],
            Instance: [],
            Platform: [],
            Region: [],
            startDate: '01-01-1900',
            endDate: '01-01-2200',
            Active: false
        });

        acquireToken((token) => {
            resetGridData(token); // Use callback to reset data
        });

        setIsOpen(false);
    };


    return (
        <Flex gap="gap.smaller" vAlign="center" style={{ alignItems: 'center' }}>
            <Text content="Applied Filters:" weight="regular" />
            <Popup
                className="my-popup"
                open={isOpen}
                trigger={<Button style={{ boxShadow: 'none' }} className="pl-3 pr-3 filter-btn" circular icon={<FilterIcon />} content="Add Filter" onClick={() => setIsOpen(true)} />}
                content={
                    <Flex column gap="gap.smaller" style={{ width: '350px', maxHeight: '600px', overflowY: 'auto' }}>
                        {loading && <Text content="Loading filter options..." />}
                        {!loading && (
                            <>
                                {elegibleFilters.includes("Product") && (
                                    <Flex gap="gap.smaller" vAlign="center">
                                        <Text content="Product" weight="semibold" style={{ minWidth: '130px' }} />
                                        <Dropdown
                                            fluid
                                            placeholder="Select Product"
                                            multiple
                                            search
                                            items={products}
                                            value={tempFilters.Product}
                                            onChange={(e, data) => handleDropdownChange("Product", e, data)}
                                            styles={{ minWidth: '200px' }}
                                        />
                                    </Flex>
                                )}
                                {elegibleFilters.includes("Instance") && (
                                    <Flex gap="gap.smaller" vAlign="center">
                                        <Text content="Instance" weight="semibold" style={{ minWidth: '130px' }} />
                                        <Dropdown
                                            fluid
                                            placeholder="Select Instance"
                                            multiple
                                            search
                                            items={instance}
                                            value={tempFilters.Instance}
                                            onChange={(e, data) => handleDropdownChange("Instance", e, data)}
                                            styles={{ minWidth: '200px' }}
                                        />
                                    </Flex>
                                )}
                                {elegibleFilters.includes("Platform") && (
                                    <Flex gap="gap.smaller" vAlign="center">
                                        <Text content="Platform" weight="semibold" style={{ minWidth: '130px' }} />
                                        <Dropdown
                                            fluid
                                            placeholder="Select Platform"
                                            multiple
                                            search
                                            items={platform}
                                            value={tempFilters.Platform}
                                            onChange={(e, data) => handleDropdownChange("Platform", e, data)}
                                            styles={{ minWidth: '200px' }}
                                        />
                                    </Flex>
                                )}
                                {elegibleFilters.includes("Region") && (
                                    <Flex gap="gap.smaller" vAlign="center">
                                        <Text content="Region" weight="semibold" style={{ minWidth: '130px' }} />
                                        <Dropdown
                                            fluid
                                            placeholder="Select Region"
                                            multiple
                                            search
                                            items={region}
                                            value={tempFilters.Region}
                                            onChange={(e, data) => handleDropdownChange("Region", e, data)}
                                            styles={{ minWidth: '200px' }}
                                        />
                                    </Flex>


                                )}
                                <Flex row gap="gap.smaller">
                                    <Text content="Start Date" weight="semibold" style={{ minWidth: '130px' }} />
                                    <Flex className="date-picker-field" gap="gap.small" vAlign="center">
                                        <Datepicker
                                            allowManualInput={false}
                                            selectedDate={tempFilters.startDate ? new Date(tempFilters.startDate) : null}
                                            onDateChange={(e, data) => handleDropdownChange('startDate', e, data)}
                                        />
                                    </Flex>
                                </Flex>
                                <Flex row gap="gap.smaller">
                                    <Text content="End Date" weight="semibold" style={{ minWidth: '130px' }} />
                                    <Flex className="date-picker-field" gap="gap.small" vAlign="center">
                                        <Datepicker
                                            allowManualInput={false}
                                            selectedDate={tempFilters.endDate ? new Date(tempFilters.endDate) : null}
                                            onDateChange={(e, data) => handleDropdownChange('endDate', e, data)}
                                        />
                                    </Flex>
                                </Flex>
                                <Flex gap="gap.smaller" vAlign="center" fill>
                                    <Text content="Active" weight="semibold" style={{ minWidth: '130px' }} />
                                    <Checkbox
                                        toggle
                                        checked={tempFilters.Active === true}
                                        onChange={() => setTempFilters(prev => ({
                                            ...prev,
                                            Active: !prev.Active // Toggle between true and false
                                        }))}
                                    />
                                </Flex>
                                <Flex gap="gap.large" justify="end" vAlign="center">
                                    <Button content="Reset" onClick={resetFilters} disabled={!Object.values(tempFilters).some(filter => filter.length > 0)} />
                                    <Button content="Cancel" onClick={closeDialog} />
                                    <Button primary content="Apply" onClick={applyFilters} />
                                </Flex>
                            </>
                        )}
                    </Flex>
                }
                on="click"
                modal
                closeOnOutsideClick
            />
        </Flex>
    );
};

export default useMultiFilterDialog;
