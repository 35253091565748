import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Loader, Button, Flex, Tooltip, Card, CardHeader, CardBody, Pill } from '@fluentui/react-northstar';
import { ExcelColorIcon, ArrowUpIcon } from '@fluentui/react-icons-northstar';
import RevenueService from "../../../services/revenueservice";
import useMultiFilterDialog from './useMultiFilterDialog';
import '../microsoftbilling/microsoftbilling.scss';

const PAGE_SIZE = 10;

const MicrosoftBillingDashboard = (props) => {
    const { acquireToken, viewStatus } = props;
    const [gridData, setGridData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [skip, setSkip] = useState(0);
    /* const [isOpen, setIsOpen] = useState(false); */

    const fetchGridData = useCallback((token, filters = {}) => {
        setLoaded(false);
        // Debugging output
        console.log('Fetching grid data with filters:', filters);

        const filterPayload = {
            ...filters,
        };
        console.log(filterPayload);

        if (Object.keys(filterPayload).some(key => filterPayload[key] !== null)) {
            RevenueService.GetMicrosoftBillingGridDataFiltered(token, filterPayload)
                .then((response) => {
                    if (response && response.status === 200) {
                        setGridData(response.data); // Keep the entire data set for calculations
                        // Slicing only affects the paginated view in the grid
                        setFilteredData(response.data.slice(skip, skip + PAGE_SIZE));
                    } else {
                        viewStatus.InformChildPageStatus(response?.status || 500);
                    }
                    setLoaded(true);
                })
                .catch((error) => {
                    console.error('Error fetching Microsoft Billing data:', error);
                    setLoaded(true);
                });
        } else {
            RevenueService.GetMicrosoftBillingGridData(token)
                .then((response) => {
                    if (response && response.status === 200) {
                        setGridData(response.data);
                        setFilteredData(response.data.slice(0, PAGE_SIZE));
                        setLoaded(true);
                    } else {
                        viewStatus.InformChildPageStatus(response?.status || 500);
                    }
                    setLoaded(true);
                })
                .catch((error) => {
                    console.error('Error fetching Microsoft Billing data:', error);
                    setLoaded(true);
                });
        }
    }, [viewStatus, skip]);

    const totalCustomers = useMemo(() => {
        return gridData.length;
    }, [gridData]);


    const resetGridData = useCallback((token) => {
        fetchGridData(token);
    }, [fetchGridData]);

    useEffect(() => {
        acquireToken((token) => {
            fetchGridData(token, appliedFilters);
        });
    }, [acquireToken, appliedFilters, skip, fetchGridData]);

    useEffect(() => {
        setFilteredData(gridData.slice(skip, skip + PAGE_SIZE));
    }, [gridData, skip]);

    /* useEffect(() => {
    if (isOpen) {
        useMultiFilterDialog.setTempFilters({
            ...useMultiFilterDialog.selectedFilters,
            startDate: useMultiFilterDialog.selectedFilters.startDate || null,
            endDate: useMultiFilterDialog.selectedFilters.endDate || null,
        });
    }
}, [useMultiFilterDialog.isOpen, useMultiFilterDialog.selectedFilters]); */



    const handleFilterUpdate = (filters) => {
        const filterPayload = {
            Product: filters.Product.length > 0 ? filters.Product.join(',') : null,
            Instance: filters.Instance.length > 0 ? filters.Instance.join(',') : null,
            Platform: filters.Platform.length > 0 ? filters.Platform.join(',') : null,
            Region: filters.Region.length > 0 ? filters.Region.join(',') : null,
            StartDate: filters.startDate || null,
            EndDate: filters.endDate || null,
            Active: filters.Active
        };
        setAppliedFilters(filterPayload);
        acquireToken((token) => {
            RevenueService.GetMicrosoftBillingGridDataFiltered(token, filterPayload)
                .then((response) => {
                    if (response.status === 200) {
                        setGridData(response.data);
                        setFilteredData(response.data.slice(0, PAGE_SIZE));
                        setSkip(0);
                    } else {
                        console.error('Error fetching filtered data:', response.statusText);
                    }
                })
                .catch((error) => {
                    console.error('Error in API call:', error);
                });
        });
    };

    const handleDownloadCsv = () => {
        console.log('Downloading with filters:', appliedFilters); // Debugging output
        setDownloading(true);
        acquireToken((token) => {
            RevenueService.DownloadMicrosoftBillingCsv(token, appliedFilters)
                .then((response) => {
                    if (response.status === 200) {
                        const blob = new Blob([response.data], { type: 'text/csv' });
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'MicrosoftBillingData.csv');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        console.error('Error downloading CSV:', response.statusText);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                })
                .finally(() => {
                    setDownloading(false);
                });
        });
    };



    const handlePageChange = (event) => {
        const newSkip = event.page.skip;
        setSkip(newSkip);
        const newFilteredData = gridData.slice(newSkip, newSkip + PAGE_SIZE);
        setFilteredData(newFilteredData);
    };

    const filterDialog = useMultiFilterDialog({
        handleFilterUpdate,
        elegibleFilters: ['Product', 'Instance', 'Platform', 'Region', 'startDate', 'endDate','Active'], // Include "Date"
        acquireToken,
        resetGridData
    });

    const RenderTooltipCell = (props) => {
        return (
            <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Tooltip
                    content={props.dataItem[props.field]}
                    trigger={<span>{props.dataItem[props.field]}</span>}
                />
            </td>
        );
    };


    const addedThisMonth = useMemo(() => {
        const currentMonth = new Date().getMonth();
        return filteredData.filter(item => {
            const itemMonth = new Date(item.SubscriptionStartDate).getMonth();
            return itemMonth === currentMonth;
        }).length;
    }, [filteredData]);

    const addedLastMonth = useMemo(() => {
        const lastMonth = new Date().getMonth() - 1;
        return filteredData.filter(item => {
            const itemMonth = new Date(item.SubscriptionStartDate).getMonth();
            return itemMonth === lastMonth;
        }).length;
    }, [filteredData]);

    const totalAmount = useMemo(() => {
        return filteredData.reduce((sum, item) => sum + item.Amount, 0);
    }, [filteredData]);

    const addedDetails = {
        thisMonth: {
            added: addedThisMonth,
            details: `Total added this month: ${addedThisMonth}`
        },
        lastMonth: {
            added: addedLastMonth,
            details: `Total added last month: ${addedLastMonth}`
        },
        totalAmountDetails: {
            amount: totalAmount.toLocaleString(),
            details: `Total amount for current filter: ${totalAmount.toLocaleString()}`
        }
    };
    const getPercentageChange = (value) => {
        // Dummy calculation; replace with real logic based on previous data or a specific baseline
        return ((value / totalCustomers) * 100).toFixed(1);
    };

    return (
        <div className="microsoft-billing-dashboard">

            <Flex space="between" className="filter-pill-container">
                {filterDialog}
                <div className='icnTrk ml-2 download-icon'>
                    {downloading ? (
                        <Loader size="smallest" />
                    ) : (
                        <Button
                            title="Download Excel"
                            icon={<ExcelColorIcon outline rotate={0} size="large" />}
                            iconOnly
                            onClick={handleDownloadCsv}
                        />
                    )}
                </div>
            </Flex>
            <div className='row d-flex align-items-stretch top-kpi'>
                <div className='col-12 col-md-3 mb-4'>
                    <Card className='card-border bg-light-purple rev-chart'>
                        <CardHeader>
                            <h6>Total Customers</h6>
                        </CardHeader>
                        <CardBody>
                            <h1>{totalCustomers}</h1>

                        </CardBody>
                    </Card>
                </div>
                <div className='col-12 col-md-3 mb-4'>
                    <Card className='card-border bg-light-purple rev-chart'>
                        <CardHeader>
                            <h6>Added This Month</h6>
                        </CardHeader>
                        <CardBody>
                            <h1>{addedDetails.thisMonth.added}</h1>
                            <Pill className='healthy'>+{getPercentageChange(addedDetails.thisMonth.added)}%<ArrowUpIcon /></Pill>
                        </CardBody>
                    </Card>
                </div>
                <div className='col-12 col-md-3 mb-4'>
                    <Card className='card-border bg-light-purple rev-chart'>
                        <CardHeader>
                            <h6>Added Last Month</h6>
                        </CardHeader>
                        <CardBody>
                            <h1>{addedDetails.lastMonth.added}</h1>
                            <Pill className='healthy'>+{getPercentageChange(addedDetails.lastMonth.added)}%<ArrowUpIcon /></Pill>
                        </CardBody>
                    </Card>
                </div>
                <div className='col-12 col-md-3 mb-4'>
                    <Card className='card-border bg-light-purple rev-chart'>
                        <CardHeader>
                            <h6>Total Amount</h6>
                        </CardHeader>
                        <CardBody>
                            <h1>£ {addedDetails.totalAmountDetails.amount}</h1>

                        </CardBody>
                    </Card>
                </div>
            </div>
            <div style={{ width: '100%', overflowX: 'auto', paddingBottom: '1rem' }}>
                <Grid
                    data={filteredData}
                    skip={skip}
                    pageable={true}
                    pageSize={PAGE_SIZE}
                    total={gridData.length}
                    onPageChange={handlePageChange}
                    resizable
                >
                    <Column
                        field="Product"
                        title="Product"
                        width="150px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="Platform"
                        title="Platform"
                        width="150px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="Region"
                        title="Region"
                        width="80px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="Instance"
                        title="Instance"
                        width="120px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="AMPSubscriptionId"
                        title="Subscription ID"
                        width="140px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="SubscriptionStartDate"
                        title="Subs Start Date"
                        width="140px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="Company"
                        title="Customer Name"
                        width="150px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="PurchaserEmail"
                        title="Email"
                        width="150px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="SubsName"
                        title="Subs Name"
                        width="150px"
                        cell={RenderTooltipCell}
                    />
                    <Column
                        field="AMPQuantity"
                        title="Quantity"
                        width="80px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="Price"
                        title="Price"
                        width="70px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="CurrencyCode"
                        title="Currency"
                        width="90px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="Amount"
                        title="Amount"
                        width="80px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="TermUnit"
                        title="Term Unit"
                        width="90px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field]}</td>}
                    />
                    <Column
                        field="Active"
                        title="Active"
                        width="80px"
                        cell={(props) => <td style={{ whiteSpace: 'nowrap' }}>{props.dataItem[props.field] ? "Yes" : "No"}</td>}
                    />
                </Grid>
            </div>
            {!loaded && (
                <div className="loading-indicator">
                    <Loader type="infinite-spinner" />
                    Loading data...
                </div>
            )}
        </div>
    );
};

export default MicrosoftBillingDashboard;
