import React, { useState, useEffect } from "react";
import {
	Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text, FormDropdown, FormCheckbox,
	Tooltip, InfoIcon, Datepicker, Dropdown
} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, FilterIcon, ExcelColorIcon } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../../shared/contexts/Context";
import MonitoringService from "../../../services/monitoringservice";
import { process } from "@progress/kendo-data-query";
import { CellDateTime, CellDateTimeSheet, CellHoursTimeSheet, CellDateTimeSheetLR } from "../../../shared/appcomponents/custom-grid-cells";
import { OPERATIONSTATUS } from '../../../shared/appcomponents/enums';
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { formatDate } from '@progress/kendo-intl';
import { Chart, ChartSeries, ChartSeriesItem, ChartAxisDefaults, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend } from "@progress/kendo-react-charts";
import { barcodeOutlineIcon } from "@progress/kendo-svg-icons";
import { align } from "@progress/kendo-drawing";
import { debounce } from "lodash";
import { FormElement } from "@progress/kendo-react-form";

//import '../../dashboard/monitoringbilling/monitoringbilling.scss';








const CUSTOMERPRIORITYLEVELS = [
	{ key: 1, header: 'true', value: true },
	{ key: 0, header: 'false', value: false }
]
const TimesheetReport = (props) => {
	const [timeSheetDetails, settimeSheetDetails] = React.useState(null);
	const [timeSheetSummary, settimeSheetSummary] = React.useState(null);
	const [timeSheetRecords, settimeSheetRecords] = React.useState(null);

	const [isLoaded, SetLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
	const [deleteAlertObj, setDeleteAlertObj] = useState(null);

	const [isAlertIdValid, SetisAlertIdValid] = React.useState(true);
	const [isAlertIdDisabled, setIsAlertIdDisabled] = useState(false);
	const [projects, setProjects] = React.useState(null);

	const [timesheetData, settimesheetData] = React.useState(null);
	const [isHoursValid, SetisHoursValid] = React.useState(true);
	const [isWorkTypeValid, SetisWorkTypeValid] = React.useState(true);
	const [isProjectValid, SetisProjectValid] = React.useState(true);
	const [isVersionValid, SetisVersionValid] = React.useState(true);
	const [filterByRadioValue, setfilterByRadioValue] = React.useState('1');

	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = useState(new Date());
	const [selectedFilter, setselectedFilter] = React.useState(null);
	const [filterByValue, setFilterByValue] = React.useState('');
	const filterByItems = ['Date', 'Work Type', 'Project'];
	const [showDateFilter, setShowDateFilter] = useState(true);
	const [showWorkTypeFilter, setshowWorkTypeFilter] = useState(false);
	const [workTypes, setWorkTypes] = React.useState(null);
	const [SelectedWorkType, setSelectedWorkType] = React.useState(null);
	const [showProjectFilter, setshowProjectFilter] = useState(false);
	const [TokenNames, setTokenNames] = React.useState(null);
	const [selectedProject, setselectedProject] = React.useState(null);
	const [filterError, setFilterError] = React.useState(false);
	// const [filterActive, setfilterActive] = React.useState(false);
	const tokenSuffix = "***************";
	const [isActive, setActive] = useState("false");
	const [logHoursError, setlogHoursError] = useState(false);
	const [missing, setMissing] = useState(false);
	const [restrictDates, setrestrictDates] = useState([]);
	const [devBAUData, setdevBAUData] = useState({});
	const [downloadingDetails, setdownloadingDetails] = React.useState(false);
	const [downloadingSummary, setdownloadingSummary] = React.useState(false);
	const [downloadingRecords, setdownloadingRecords] = React.useState(false);
	const [emailList, setemailList] = React.useState(null);
	const [selectedEmail, setselectedEmail] = React.useState(null);
	const [isFilterActive, setisFilterActive] = React.useState(false);
	const [csvDialogVisible, setCsvDialogVisible] = useState(false);
	const [csvFile, setCsvFile] = useState(null);
	const [matchedRecords, setMatchedRecords] = useState([]);
	const [syncMessage, setSyncMessage] = useState('');
	const [syncMessageType, setSyncMessageType] = useState('');
	const [emptyDataMessage, setEmptyDataMessage] = useState("");
	const [popupType, setPopupType] = useState("");  //Db check
	const [loading, setLoading] = useState(false);
	const [IsTimesheetReady, setTimesheetReady] = React.useState(false);
	const [startDate, setStartDate] = useState(new Date()); // Default to today's date
	const [endDate, setEndDate] = useState(new Date());
	const [dialogProcessed, setDialogProcessed] = useState(false);
	const [startDateError, setStartDateError] = useState("");
	const [endDateError, setEndDateError] = useState("");
	const [csvFileError, setCsvFileError] = useState("");



	const handleOpenCsvDialog = () => {
		setCsvDialogVisible(true);
	};

	const handlePopupClose = () => {
		setEmptyDataMessage("");
		setPopupType("");
	};
	const handleCloseCsvDialog = () => {
		setCsvDialogVisible(false);
		resetDialogState(); // Reset dialog state
	};

	const resetDialogState = () => {
		setCsvFile(null);
		setStartDate(null);
		setEndDate(null);
		setStartDateError("");
		setEndDateError("");
		setCsvFileError("");
		setSyncMessage("");
		setSyncMessageType("");
		setDialogProcessed(false);
	};


	const handleFileChange = (e) => {
		setCsvFile(e.target.files[0]);
		setCsvFileError(""); // Clear any existing file error
	};

	const formatToLocalDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	const handleSubmit = () => {
		console.log("--- Submit Button Clicked ---");

		let valid = true;

		// Validation checks
		if (!startDate) {
			setStartDateError("Start date is required.");
			valid = false;
		} else {
			setStartDateError("");
		}

		if (!endDate) {
			setEndDateError("End date is required.");
			valid = false;
		} else if (startDate > endDate) {
			setEndDateError("End date cannot be earlier than Start date.");
			valid = false;
		} else {
			setEndDateError("");
		}

		if (!csvFile) {
			setCsvFileError("Please upload a valid CSV file, Start Date and End Date.");
			valid = false;
		} else {
			setCsvFileError("");
		}

		console.log("Validation Result:", valid ? "Valid" : "Invalid");

		if (!valid) {
			console.log("Validation Result: Invalid");
			return;
		}

		console.log("Validation Result: Valid");

		props.acquireToken((token) => {
			if (token) {
				setLoading(true);

				const formData = new FormData();
				formData.append("file", csvFile);
				formData.append("startDate", formatToLocalDate(startDate));
				formData.append("endDate", formatToLocalDate(endDate));

				console.log("Form Data being submitted:", {
					file: csvFile.name,
					startDate: formatToLocalDate(startDate),
					endDate: formatToLocalDate(endDate),
				});

				MonitoringService.SubmitTimesheetRecords(token, formData)
					.then((response) => {
						console.log("Response:", response);
						if (response.status === 200) {
							setSyncMessage(response.data.message || "Operation successful.");
							setSyncMessageType("Success");
						} else {
							setSyncMessage(response.data.message || "An unknown error occurred.");
							setSyncMessageType("Error");
						}

						setTimeout(handleCloseCsvDialog, 10000);
					})
					.catch((error) => {
						console.error("Error:", error);
						setSyncMessage(`Error: ${error.message}`);
						setSyncMessageType("Error");

						setTimeout(handleCloseCsvDialog, 10000);
					})
					.finally(() => setLoading(false));
			}
		});
	};


	const versionRegex = /^(\d+|x|X)\.(\d+|x|X)\.(\d+|x|X)\.(\d+|x|X)$/gm;

	let hoursItems = [];

	const steps = {
		hour: 1,
	};
	const defaultHours = new Date(2000, 2, 10, 1, 0);
	const labelContent = (e) => (e.percentage * 100).toFixed() + '%';

	const DevBAUChart = (p) => {

		const b = [+devBAUData.BAUHours];
		const d = [+devBAUData.DevHours];
		const seriesLabelsBAU = {
			visible: true,
			position: 'center',
			content: labelContent,
			background: 'transparent',
			margin: {
				left: 20
			},
			color: '#000000'
		};

		const seriesLabelsDev = {
			visible: true,
			position: 'center',
			margin: {
			},
			background: 'transparent',
			content: labelContent,
			color: '#ffffff'
		};

		return (

			<Chart style={{ height: '100px' }} >
				<ChartLegend position="top" orientation="horizontal" />
				<ChartAxisDefaults majorGridLines={{ visible: false, }} minorGridLines={{ visible: false, }} visible={false} />
				<ChartSeries>
					<ChartSeriesItem name="BAU Hours(%)" type="bar" data={b} labels={seriesLabelsBAU} stack={{ type: "100%", }}
						color="#FFBB00" tooltip={{ visible: true }} />
					<ChartSeriesItem name="New Dev Hours(%)" type="bar" data={d} labels={seriesLabelsDev} color="#29648a" tooltip={{ visible: true }} />
				</ChartSeries>
			</Chart>


		);
	};

	const ChartContainer = (p) => {

		let bau = (+p.dataItem.BAUHours) / (+p.dataItem.BAUHours + +p.dataItem.DevHours)
		bau = (bau * 100).toFixed();
		let dev = 100 - bau;
		const b = [+p.dataItem.BAUHours];
		const d = [+p.dataItem.DevHours];
		const seriesLabelsBAU = {
			visible: true,
			position: 'center',

			content: labelContent,
			background: 'transparent',
			margin: {
				left: 20
			},
		};

		const seriesLabelsDev = {
			visible: true,
			position: 'center',
			margin: {
			},

			content: labelContent,
		};

		return (
			<td>
				<Chart style={{ height: '20px' }} >
					<ChartAxisDefaults
						majorGridLines={{
							visible: false,
						}}
						minorGridLines={{
							visible: false,
						}}
						visible={false}
					/>
					<ChartSeries>
						<ChartSeriesItem type="bar" data={b} labels={seriesLabelsBAU}
							stack={{
								type: "100%",
							}}
							color="#FFBB00"
						/>
						<ChartSeriesItem type="bar" data={d} labels={seriesLabelsDev} color="#3689bf" />
					</ChartSeries>
				</Chart>
			</td>

		);
	};

	const [dsDetails, setdsDetails] = React.useState({
		sort: [
			{
				field: "alertId",
				dir: "asc",
			},
		],
		take: 10,
		skip: 0,
	});

	const [dsSummary, setdsSummary] = React.useState({
		sort: [
			{
				field: "alertId",
				dir: "asc",
			},
		],
		take: 10,
		skip: 0,
	});

	const [dsRecords, setdsRecords] = React.useState({
		sort: [
			{
				field: "alertId",
				dir: "asc",
			},
		],
		take: 10,
		skip: 0,
	});

	for (let i = 0; i < 10; i++) {
		hoursItems.push(i.toString())
	}

	let rdates = [];
	let d = new Date();
	d.setMonth(0);
	d.setDate(1);
	for (let i = 1; i < 365; i++) {
		d.setDate(d.getDate() + 1);
		if (d.getDay() == 0 || d.getDay() == 6) {
			rdates.push(new Date(d));
		}
	}

	React.useEffect(() => {
		props.acquireToken(authSuccessCallback);

	}, []);

	const authSuccessCallback = (token) => {
		let d = new Date();
		let l = d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate() + ' 00:00';
		let param = {
			email: '-1',
			isDateFilter: 0,
			l: l,
			r: l,
		};
		let chartData = {};
		if (token) {
			MonitoringService.GetTimeSheetReport(token, param).then((response) => {
				if (response && response.status == 200) {
					response = response.data;
					settimeSheetDetails(response.TimesheetDetails);
					settimeSheetSummary(response.TimesheetSummary);
					settimeSheetRecords(response.TimesheetRecords);
					chartData.DevHours = +response.DevHours;
					chartData.BAUHours = +response.BAUHours;
					setdevBAUData(chartData);
					setemailList(response.EmailList);
					setTimesheetReady(response.IsDownloadReady);
				}
				else if (response && response.status == 401) {
					props.viewStatus.InformChildPageStatus(response.status);
				}
				else {
					props.viewStatus.InformChildPageStatus(500);
				}
				SetLoaded(true);
			});
		}
	};

	const filterGridData = (value) => {
		let filterDetails = {
			logic: "or",
			filters: [{ field: "EmployeeNo", operator: "contains", value: value },
			{ field: "EmployeeName", operator: "contains", value: value },
			{ field: "EmailId", operator: "contains", value: value },
			{ field: "Date", operator: "contains", value: value },
			{ field: "RecordId", operator: "contains", value: value },
			],
		};

		let filterSummary = {
			logic: "or",
			filters: [{ field: "EmployeeNo", operator: "contains", value: value },
			{ field: "EmployeeName", operator: "contains", value: value },
			{ field: "EmailId", operator: "contains", value: value },
			{ field: "L", operator: "contains", value: value },
			],
		};

		let filterRecords = {
			logic: "or",
			filters: [{ field: "EmployeeNo", operator: "contains", value: value },
			{ field: "EmployeeName", operator: "contains", value: value },
			{ field: "EmailId", operator: "contains", value: value },
			{ field: "L", operator: "contains", value: value },
			],
		};


		setdsDetails({ ...dsDetails, filter: filterDetails });

		setdsSummary({ ...dsSummary, filter: filterSummary });

		setdsRecords({ ...dsRecords, filter: filterRecords });
	};

	const handleAddEditClickOpen = (dataItem, isEdit) => {
		SetisAlertIdValid(true);
		setVisible(true);
		SetisHoursValid(true);
		SetisWorkTypeValid(true);
		SetisProjectValid(true);
		SetisVersionValid(true);
		setlogHoursError(false);
		// let hrs = 0;
		// let mins = 0;
		let dt = {};
		dt.Date = new Date();
		// dt.Hours = defaultHours;

		if (isEdit === true) {
			dataItem.Date = new Date(Date.parse(dataItem.Date));
			// if(dataItem.Hours && dataItem.Hours.length > 0){
			// 	let arr = dataItem.Hours.split(':');
			// 	if(arr.length > 1){
			// 		hrs = arr[0];
			// 		mins= arr[1];
			// 	}
			// 	dataItem.Hours =  defaultHours;
			// 	dataItem.Hours.setHours(hrs);
			// 	dataItem.Hours.setMinutes(mins);
			// }

			settimesheetData(dataItem);
			setEditMode(true);
			setIsAlertIdDisabled(true);
			// checkIsValid();
		}
		else {
			settimesheetData(dt);
			setIsAlertIdDisabled(false);
		}
	};

	const handleAddEditClickClose = () => {
		setVisible(false);
		settimesheetData(null);
		setEditMode(false);
		setIsValid(false);
		setlogHoursError(false);
	};

	const ActionIconVersions = (props) => {
		return (
			<td style={{ textAlign: "center" }}>
				<Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)} />
				{/* <Button
					styles={{ height: "auto" }}
					iconOnly
					size="small"
					icon={<TrashCanIcon />}
					text
					onClick={() => {
						setDeleteAlertObj(props.dataItem);
						setDeleteConfirmationDialog(true);
					}}
				/> */}
			</td>
		);
	};

	const removeData = () => {
		if (deleteAlertObj != null) {
			props.acquireToken((token) => {
				if (token) {
					let _params = {
						RecordId: deleteAlertObj.RecordId,
					};
					MonitoringService.RemoveTimeSheet(_params, token).then(
						(response) => {
							if (response && response.status == 200) {
								response = response.data;
								props.acquireToken(authSuccessCallback);
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
							}
							else if (response && response.status == 401) {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);

							}
							else {
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}
						}
					);
				}
			});
		}
		setDeleteConfirmationDialog(false);
		setDeleteAlertObj(null);
	};

	const addEditSubmitClickHandler = () => {
		let isValid = CheckForm();
		let tsData = timesheetData;
		let date = new Date(tsData.Date);
		let date_sub = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
		tsData.DateSubmit = date_sub;
		tsData.IsEdit = editMode;
		setlogHoursError(false);

		// let hrs = tsData.Hours;

		// tsData.Hours =  hrs.getHours() + ':' + hrs.getMinutes();

		if (isValid == true) {
			props.acquireToken((token) => {
				if (token) {

					MonitoringService.CheckTimeSheet(tsData, token).then((resCheck) => {

						if (resCheck && resCheck.data == '0') {

							setlogHoursError(true);
							// props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
						}
						else if (resCheck && resCheck.data == '1') {
							if (editMode) {
								MonitoringService.EditTimeSheet(tsData, token).then((response) => {
									if (response && response.data == '-1') {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
									}
									else if (response && response.status == 200) {
										response = response.data;
										props.acquireToken(authSuccessCallback);
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
									} else if (response && response.status == 401) {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
									}
									else {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
									}
								});
							} else {
								MonitoringService.AddTimeSheet(tsData, token).then((response) => {
									if (response && response.data == '-1') {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
									}
									if (response && response.status == 200) {
										response = response.data;
										props.acquireToken(authSuccessCallback);
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
									} else if (response && response.status == 401) {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
									}
									else {
										props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
									}
								});
							}
							setVisible(false);
							setEditMode(false);
							setIsValid(false);
							setlogHoursError(false);
						}
					});
				}
			});
		}

	};

	const DetailComponentSm = (props1) => {
		const dataItem = props1.dataItem;
		return (
			<section>
				<FluentGrid columns={1}>
					<Text weight="bold" content="Cancel ID:" />
					<Text content={dataItem.cancelID} />
					<Text weight="bold" content="Service Name:" />
					<Text content={dataItem.serviceName} />
					<Text weight="bold" content="Details:" />
					<Text content={dataItem.details} />
					<Text weight="bold" content="Steps:" />
					<Text content={dataItem.Steps} />
					<Text weight="bold" content="Tenanted Alert:" />
					{
						props1.dataItem.isTenanted ?
							<Status size="larger" state="success" icon={<AcceptIcon />} />
							:
							<Status size="larger" state="error" icon={<CloseIcon />} />
					}
					<Text weight="bold" content="Call Data Alert:" />
					{
						props1.dataItem.isCallDataAlert ?
							<Status size="larger" state="success" icon={<AcceptIcon />} />
							:
							<Status size="larger" state="error" icon={<CloseIcon />} />
					}

				</FluentGrid>
			</section>
		);
	};

	const CheckForm = () => {
		let isValid = true;
		let isValidHours = true;
		let isValidWorkType = true;
		let isValidProject = true;
		let isVersionValid = true;


		if (timesheetData?.Hours && timesheetData?.Hours.toString().length > 0) {
			isValidHours = true;
		}
		else {
			isValidHours = false;
			SetisHoursValid(false);
		}

		if (timesheetData?.Project && timesheetData?.Project.toString().length > 0) {
			isValidProject = true;
		}
		else {
			isValidProject = false;
			SetisProjectValid(false);
		}

		if (timesheetData?.WorkType && timesheetData?.WorkType.toString().length > 0) {
			isValidWorkType = true;
		}
		else {
			isValidWorkType = false;
			SetisWorkTypeValid(false);
		}

		if (timesheetData?.Version && versionRegex.test(timesheetData?.Version.trim()) == true) {
			isVersionValid = true;
		}
		else {
			isVersionValid = false;
			SetisVersionValid(false);
		}


		if (isValidHours && isValidProject && isValidWorkType && isVersionValid) {
			isValid = true;
		}
		else {
			isValid = false;
		}



		return isValid;

	};

	const expandChange = (event) => {
		let newData = timeSheetDetails.map((item) => {
			if (item.RecordId === event.dataItem.RecordId) {
				item.expanded = !event.dataItem.expanded;
			}

			return item;
		});
		settimeSheetDetails(newData);
	};

	const handleWorkTypeChange = (alwaysNull, data) => {

		if (data.value.header && data.value.header.length > 0) {
			SetisWorkTypeValid(true);
		}
		else {
			SetisWorkTypeValid(false);
		}

		settimesheetData({
			...timesheetData,
			WorkType: data.value.header,
			WorkTypeId: data.value.value,
		})
	};

	const handleEmailChange = (alwaysNull, data) => {


	};

	const handleProjectChange = (alwaysNull, data) => {

		if (data.value.header && data.value.header.length > 0) {
			SetisProjectValid(true);
		}
		else {
			SetisProjectValid(false);
		}

		settimesheetData({
			...timesheetData,
			Project: data.value.header,
			ProjectId: data.value.value,
		})
	};

	const handleHoursChange = (alwaysNull, data) => {

		if (data.value.header && data.value.header.length > 0) {
			SetisHoursValid(true);
		}
		else {
			SetisHoursValid(false);
		}

		settimesheetData({
			...timesheetData,
			Hours: data.value.header,
		})
	};

	const handleFilterDdlChange = (_selectedType) => {

		setFilterError(false);
		if (filterByRadioValue == '1') {
			setShowDateFilter(false);
			setshowWorkTypeFilter(false);
			setshowProjectFilter(false);
			if (_selectedType == 'Date') {
				setShowDateFilter(true);
			}
			else if (_selectedType == 'Work Type') {
				setshowWorkTypeFilter(true);
			}
			else if (_selectedType == 'Project') {
				setshowProjectFilter(true);
			}
		}
		setselectedFilter(_selectedType);
		setFilterByValue('');
	}

	const handleEndDateChange = (event) => {
		setSelectedEndDate(event.value);
	};

	const handleStartDateChange = (event) => {
		setSelectedStartDate(event.value);
	};

	const handleFilterByTextChange = (e, data) => {
		setFilterByValue(data.value);
	}

	const handleWorkTypeChangeFilter = (alwaysNull, data) => {
		setSelectedWorkType(data.value);
	};

	const handleTokenNameChange = (alwaysNull, data) => {
		setselectedProject(data.value);
	};

	const handleEmailChangeFilter = (alwaysNull, data) => {
		setselectedEmail(data.value);
	};

	const applyFilter = () => {
		// if (filterByRadioValue == "1" && selectedFilter === null) {
		// 	setFilterError(true);
		// 	return;
		// }

		SetLoaded(false);
		setActive("false");
		setisFilterActive(true);
		let chartData = {};
		let sd = new Date(selectedStartDate);
		let startDate = sd.getFullYear() + '/' + (sd.getMonth() + 1) + '/' + sd.getDate() + ' 00:00'
		let ed = new Date(selectedEndDate);
		let endDate = ed.getFullYear() + '/' + (ed.getMonth() + 1) + '/' + ed.getDate() + ' 00:00'
		let filterByValueData = filterByValue;

		let email = selectedEmail;
		if (selectedEmail && selectedEmail.length > 0) {
			email = selectedEmail;
		}
		else {
			email = '-1';
		}

		props.acquireToken((token) => {
			if (token) {
				let _params = {
					l: startDate,
					r: endDate,
					email: email,
					isDateFilter: 1
				};

				MonitoringService.GetTimeSheetReport(token, _params).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						settimeSheetDetails(response.TimesheetDetails);
						settimeSheetSummary(response.TimesheetSummary);
						settimeSheetRecords(response.TimesheetRecords);
						chartData.DevHours = +response.DevHours;
						chartData.BAUHours = +response.BAUHours;
						setdevBAUData(chartData);
					}
					else if (response && response.status == 401) {
						props.viewStatus.InformChildPageStatus(response.status);
					}
					else {
						props.viewStatus.InformChildPageStatus(500);
					}
					SetLoaded(true);
				});

				// MonitoringService.GetTimeSheets(token, _params).then((response) => {
				// 	if (response && response.status == 200) {
				// 		response = response.data;
				// 		settimeSheetDetails(response.Timesheets);
				// 		settimeSheetSummary(response.Timesheets);
				// 	}
				// 	else if (response && response.status == 401) {
				// 		props.viewStatus.InformChildPageStatus(response.status);
				// 	}
				// 	else {
				// 		props.viewStatus.InformChildPageStatus(500);
				// 	}
				// 	SetLoaded(true);
				// 	setfilterActive(true);
				// });
			}
		});
	}

	const reset = () => {
		SetLoaded(false);
		setActive("false");
		setSelectedStartDate(new Date());
		setSelectedEndDate(new Date());
		setselectedEmail(null);

		setisFilterActive(false);
		let d = new Date();
		let l = d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate() + ' 00:00';
		let param = {
			email: '-1',
			isDateFilter: 0,
			l: l,
			r: l,
		};
		let chartData = {};

		props.acquireToken((token) => {
			if (token) {

				MonitoringService.GetTimeSheetReport(token, param).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						settimeSheetDetails(response.TimesheetDetails);
						settimeSheetSummary(response.TimesheetSummary);
						settimeSheetRecords(response.TimesheetRecords);
						chartData.DevHours = +response.DevHours;
						chartData.BAUHours = +response.BAUHours;
						setdevBAUData(chartData);
					}
					else if (response && response.status == 401) {
						props.viewStatus.InformChildPageStatus(response.status);
					}
					else {
						props.viewStatus.InformChildPageStatus(500);
					}
					SetLoaded(true);
					setActive("false");
				});
			}
		});
	}


	const downloadCsvHandler = (type) => {

		// Proceed with download logic if all leave dates are synced
		let sd = new Date(selectedStartDate);
		let startDate = `${sd.getFullYear()}/${sd.getMonth() + 1}/${sd.getDate()} 00:00`;
		let ed = new Date(selectedEndDate);
		let endDate = `${ed.getFullYear()}/${ed.getMonth() + 1}/${ed.getDate()} 00:00`;
		let email = selectedEmail && selectedEmail.length > 0 ? selectedEmail : '-1';

		props.acquireToken((token) => {
			let _params = isFilterActive
				? { type: type, email: email, isDateFilter: 1, L: startDate, R: endDate }
				: { type: type, email: '-1', isDateFilter: 0, L: '', R: '' };

			if (token) {
				// Set downloading state based on type
				if (type === 'details') {
					setdownloadingDetails(true);
					MonitoringService.DownloadTimsheetCsv(token, _params).then((response) => {
						if (response) {
							if (response.status === 200) {
								// Successful file download
								const href = URL.createObjectURL(response.data);

								// Create "a" HTML element with href to file & click
								const link = document.createElement('a');
								link.href = href;
								link.setAttribute('download', `${type}.csv`);
								document.body.appendChild(link);
								link.click();

								// Clean up "a" element & remove ObjectURL
								document.body.removeChild(link);
								URL.revokeObjectURL(href);
							} else if (response.status === 204) {
								// No content available for download
								setEmptyDataMessage("Wait for delete sync, Please try again later.....");
							} else if (response.status === 401) {
								// Unauthorized
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
							} else {
								// Other failure statuses
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}
						} else {
							// Handle case where response is undefined or null
							props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
						}

						// Reset downloading states
						setdownloadingDetails(false);
						setdownloadingSummary(false);
						setdownloadingRecords(false);
					}).catch((error) => {
						// Catch any unexpected errors and reset downloading states
						setdownloadingDetails(false);
						setdownloadingSummary(false);
						setdownloadingRecords(false);
						console.error("Download error:", error);
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
					});



				}

				else if (type === 'summary') {

					setdownloadingSummary(true);
					MonitoringService.DownloadTimsheetCsv(token, _params).then((response) => {
						if (response) {
							if (response.status === 200) {
								// Successful file download
								const href = URL.createObjectURL(response.data);

								// Create "a" HTML element with href to file & click
								const link = document.createElement('a');
								link.href = href;
								link.setAttribute('download', `${type}.csv`);
								document.body.appendChild(link);
								link.click();

								// Clean up "a" element & remove ObjectURL
								document.body.removeChild(link);
								URL.revokeObjectURL(href);
							} else if (response.status === 204) {
								// No content available for download
								setEmptyDataMessage("Wait for delete sync, Please try again later.....");
							} else if (response.status === 401) {
								// Unauthorized
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
							} else {
								// Other failure statuses
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}
						} else {
							// Handle case where response is undefined or null
							props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
						}

						// Reset downloading states
						setdownloadingDetails(false);
						setdownloadingSummary(false);
						setdownloadingRecords(false);
					}).catch((error) => {
						// Catch any unexpected errors and reset downloading states
						setdownloadingDetails(false);
						setdownloadingSummary(false);
						setdownloadingRecords(false);
						console.error("Download error:", error);
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
					});
				}
				else if (type === 'records') {
					console.log(IsTimesheetReady);
					if (IsTimesheetReady) {
						setdownloadingRecords(true);
						MonitoringService.DownloadTimsheetCsv(token, _params).then((response) => {
							if (response) {
								if (response.status === 200) {
									// Successful file download
									const href = URL.createObjectURL(response.data);

									// Create "a" HTML element with href to file & click
									const link = document.createElement('a');
									link.href = href;
									link.setAttribute('download', `${type}.csv`);
									document.body.appendChild(link);
									link.click();

									// Clean up "a" element & remove ObjectURL
									document.body.removeChild(link);
									URL.revokeObjectURL(href);
								} else if (response.status === 204) {
									// No content available for download
									setEmptyDataMessage("Wait for delete sync, Please try again later.....");
								} else if (response.status === 401) {
									// Unauthorized
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
								} else {
									// Other failure statuses
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
								}
							} else {
								// Handle case where response is undefined or null
								props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}

							// Reset downloading states
							setTimesheetReady(false);
							setdownloadingDetails(false);
							setdownloadingSummary(false);
							setdownloadingRecords(false);
						}).catch((error) => {
							// Catch any unexpected errors and reset downloading states
							setdownloadingDetails(false);
							setdownloadingSummary(false);
							setdownloadingRecords(false);
							console.error("Download error:", error);
							props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
						});
					}
					else {
						alert("Download Not Ready");
					}
				};

				/* MonitoringService.DownloadTimsheetCsv(token, _params).then((response) => {
					if (response) {
						if (response.status === 200) {
							// Successful file download
							const href = URL.createObjectURL(response.data);
	
							// Create "a" HTML element with href to file & click
							const link = document.createElement('a');
							link.href = href;
							link.setAttribute('download', `${type}.csv`);
							document.body.appendChild(link);
							link.click();
	
							// Clean up "a" element & remove ObjectURL
							document.body.removeChild(link);
							URL.revokeObjectURL(href);
						} else if (response.status === 204) {
							// No content available for download
							setEmptyDataMessage("Wait for delete sync, Please try again later.....");
						} else if (response.status === 401) {
							// Unauthorized
							props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
						} else {
							// Other failure statuses
							props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
						}
					} else {
						// Handle case where response is undefined or null
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
					}
	
					// Reset downloading states
					setdownloadingDetails(false);
					setdownloadingSummary(false);
					setdownloadingRecords(false);
				}).catch((error) => {
					// Catch any unexpected errors and reset downloading states
					setdownloadingDetails(false);
					setdownloadingSummary(false);
					setdownloadingRecords(false);
					console.error("Download error:", error);
					props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
				}); */
			}
		});
	};





	const ToggleClass = () => {
		setActive(!isActive);
	};

	return isLoaded ? (
		<WindowContext.Consumer>
			{(context) => (
				<>
					<div className="row h-100" style={{ marginTop: "-12px" }}>

						<div className="col-md-12" >
							<div className="d-flex justify-content-between align-items-center" >
								<div className="f-size-18" style={{ paddingLeft: "2px" }}>Timesheet Report</div>

								<div className="d-flex justify-content-between missing-container" >
									<div className=" float-right text-right position-relative timesheet-filter">

										{/* Button to open CSV Upload Dialog */}
										<Button
											styles={{ padding: '20 12px', marginLeft: "10px", marginRight: "10px" }}
											content="Upload CSV"
											icon={<ExcelColorIcon />}
											onClick={() => {
												console.log("Button clicked!"); // Debugging log
												handleOpenCsvDialog();
											}}
										/>

										<Input
											onChange={(e, { name, value }) => {
												filterGridData(value);
											}}
											inverted
											icon={<SearchIcon />}
											placeholder="Search..."
											className="timesheet-input"
										/>

										<Button styles={{ padding: '10 12px', marginLeft: "10px" }} icon={<FilterIcon />} content={"Filters"} title="Filters" className="filetr-icon" circular onClick={ToggleClass} />


										<Flex gap="gap.small" className={isActive ? "email-log-filter email-service" : "active email-log-filter email-service"} style={{ width: '400px', textAlign: 'left' }}>

											<div className="radio-container" style={{ position: 'relative' }}>
												<div>

													<fieldset>
														<legend>Date</legend>
														<div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '5px' }}>
															<Text content="Start Date" size="medium" weight="regular" />
															<div>
																<Datepicker allowManualInput={false} selectedDate={selectedStartDate}
																	onDateChange={(e, data) => {
																		setSelectedStartDate(data.value);
																	}} />
															</div>
														</div>
														<div className="d-flex justify-content-between align-items-center">
															<Text content="End Date" size="medium" weight="regular"
															/>
															<Datepicker allowManualInput={false} fluid
																width={210}
																selectedDate={selectedEndDate}
																onDateChange={(e, data) => {
																	setSelectedEndDate(data.value);
																}} />

														</div>
													</fieldset>
													{/* <div style={{ marginLeft: "7px" }}>
																	<Text styles={{ verticalAlign: '-webkit-baseline-middle' }}>Date: </Text>
																</div> */}
												</div>

												<div >
													<div className="d-flex justify-content-between align-items-center" style={{ height: '70px', marginLeft: '5px' }} >
														<Text content="Email" size="medium" weight="regular"
														/>
														<FormDropdown
															style={{ textAlign: 'left', width: '200px', marginRight: '2px' }}
															items={emailList}
															fluid value={selectedEmail}
															onChange={handleEmailChangeFilter} width={150}
														/>
													</div>
												</div>
												{/* {
																filterError ?
																	<div className="error-message" style={{ position: 'absolute', top: '-14px', left: '4px', fontSize: '12px' }}>
																		<Text className="text-danger">*Select Filter</Text>
																	</div>
																	: ""
															} */}
											</div>

											<Flex gap="gap.small" className="mt-2" style={{ marginLeft: '4px' }}>
												<Button content="Apply"
													onClick={applyFilter} />
												<Button content="Cancel" className="aws-applyFilter"
													onClick={() => setActive("false")} />

												{isFilterActive && (
													<Button styles={{ padding: '0 12px', marginRight: "10px" }}
														content={"Clear Filters"} title="Clear" className="filetr-icon" circular
														onClick={reset} />
												)}
											</Flex>
										</Flex>
									</div>
								</div>
							</div>
						</div>


						<div className="col-md-12" style={{ marginTop: '8px' }} >
							<div>
								<Card className="card-border bg-white h-100" fluid style={{ border: '1px solid #e1e1e1' }}>
									<CardHeader style={{ marginBottom: '-12px' }}>
										<div className="f-size-18">BAU / New Dev</div>

									</CardHeader>
									<CardBody>
										{/* <div className="f-size-17">Timesheet Details</div> */}
										<div>
											<div style={{ maxWidth: '1000px', margin: '0 auto' }}>
												<DevBAUChart >
												</DevBAUChart>
											</div>
										</div>

									</CardBody>
								</Card>
							</div>

						</div>

						<div className="col-md-12" style={{ marginTop: '8px' }}>
							<Card className="card-border bg-white h-100" fluid style={{ border: '1px solid #e1e1e1' }}>
								{/* <CardHeader className="mb-2">
									

								</CardHeader> */}
								<CardBody>
									<div className="d-flex justify-content-between align-items-center">
										<div className="f-size-17">Timesheet Details</div>
										<div style={{ position: 'relative' }}>
											{downloadingDetails ? (
												<Loader size="smallest" />
											) : (
												<Button
													title="Download CSV"
													icon={<ExcelColorIcon outline rotate={0} size="large" />}
													iconOnly
													onClick={() => {
														downloadCsvHandler('details');
													}}
												/>
											)}
											{emptyDataMessage && (
												<>
													<div className="popup-backdrop" onClick={handlePopupClose}></div>
													<div className="popup-message">
														<p>{emptyDataMessage}</p>
														<button onClick={handlePopupClose}>Close</button>
													</div>
												</>
											)}
										</div>
									</div>

									<div className='grid-with-search'>
										<Grid

											// pageable={true}
											pageable={{
												buttonCount: 3
											}}
											sortable={true}
											data={process(timeSheetDetails, dsDetails)}
											{...dsDetails}
											onDataStateChange={(e) => {
												setdsDetails(e.dataState);
											}}
											detail={context.mediaCategory.sm ? DetailComponentSm : null}
											expandField="expanded"
											onExpandChange={expandChange}
										>

											<Column field="EmployeeNo" title="Employee ID" width={120} />

											{!context.mediaCategory.sm && (
												<Column field="EmployeeName" title="Name" width={250} />
											)}

											{!context.mediaCategory.sm && (
												<Column field="EmailId" title="Email" width={250} />
											)}

											{!context.mediaCategory.sm && (
												<Column field="Date" title="Date" width={200} cell={CellDateTimeSheet} />
											)}

											{!context.mediaCategory.sm && (
												<Column field="RecordId" title="TID" width={80} />
											)}

											{!context.mediaCategory.sm && (
												<Column field="BAUHours" title="BAU Hours" width={100} />
											)}

											{!context.mediaCategory.sm && (
												<Column field="DevHours" title="New Dev Hours" width={100} />
											)}

										</Grid>
									</div>
								</CardBody>
							</Card>
						</div>

						<div className="col-md-12" style={{ marginTop: '8px' }}>
							<Card className="card-border bg-white h-100" fluid style={{ border: '1px solid #e1e1e1' }}>

								<CardBody>

									<div className="d-flex justify-content-between align-items-center">
										<div className="f-size-17">Timesheet Summary</div>
										<div style={{ position: 'relative' }}>
											{downloadingSummary ? (
												<Loader size="smallest" />
											) : (
												<Button
													title="Download CSV"
													icon={<ExcelColorIcon outline rotate={0} size="large" />}
													iconOnly
													onClick={() => {
														downloadCsvHandler('summary');
													}}
												/>
											)}
											{emptyDataMessage && (
												<>
													<div className="popup-backdrop" onClick={handlePopupClose}></div>
													<div className="popup-message">
														<p>{emptyDataMessage}</p>
														<button onClick={handlePopupClose}>Close</button>
													</div>
												</>
											)}
										</div>
									</div>

									<div className='grid-with-search'>
										<Grid
											// pageable={true}
											pageable={{
												buttonCount: 3
											}}
											sortable={true}
											data={process(timeSheetSummary, dsSummary)}
											{...dsSummary}
											onDataStateChange={(e) => {
												setdsSummary(e.dataState);
											}}
											detail={context.mediaCategory.sm ? DetailComponentSm : null}
											expandField="expanded"
											onExpandChange={expandChange}
										>

											<Column field="EmployeeNo" title="Employee ID" width={80} />

											{!context.mediaCategory.sm && (
												<Column field="EmployeeName" title="Employee ID" width={120} />
											)}

											{!context.mediaCategory.sm && (
												<Column field="EmailId" title="Email" width={160} />
											)}

											{!context.mediaCategory.sm && (
												<Column field="L" title="Start Date-End Date" width={150} cell={CellDateTimeSheetLR} />
											)}

											{!context.mediaCategory.sm && (
												<Column field="BAUHours" title="BAU Hours" width={80} />
											)}

											{!context.mediaCategory.sm && (
												<Column field="DevHours" title="New Dev Hours" width={80} />
											)}

											{!context.mediaCategory.sm && (
												<Column field="DevHours" title="BAU/New Dev" width={120} cell={ChartContainer} />
											)}

										</Grid>
									</div>
								</CardBody>
							</Card>


						</div>
						<div className="col-md-12" style={{ marginTop: '8px' }}>
							<Card className="card-border bg-white h-100" fluid style={{ border: '1px solid #e1e1e1' }}>
								<CardBody>
									<div className="d-flex justify-content-between align-items-center">
										<div className="f-size-17">Timesheet Records</div>
										<div style={{ position: 'relative' }}>
											{downloadingRecords ? (
												<Loader size="smallest" />
											) : (


												<Button
													title="Download CSV"
													icon={<ExcelColorIcon outline rotate={0} size="large" />}
													iconOnly disabled={!IsTimesheetReady}
													onClick={() => {
														downloadCsvHandler('records');
													}} />



											)}
											{emptyDataMessage && (
												<>
													<div className="popup-backdrop" onClick={handlePopupClose}></div>
													<div className="popup-message">
														<p>{emptyDataMessage}</p>
														<button onClick={handlePopupClose}>Close</button>
													</div>
												</>
											)}
										</div>
									</div>
									<div className='grid-with-search'>
										<Grid
											pageable={{
												buttonCount: 3
											}}
											sortable={true}
											data={process(timeSheetRecords, dsRecords)}
											{...dsRecords}
											onDataStateChange={(e) => {
												setdsRecords(e.dataState);
											}}
											detail={context.mediaCategory.sm ? DetailComponentSm : null}
											expandField="expanded"
											onExpandChange={expandChange}
										>
											<Column field="EmployeeNo" title="Employee ID" width={110} />
											{!context.mediaCategory.sm && (
												<Column field="EmployeeName" title="Name" width={140} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="EmailId" title="Email" width={200} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="Date" title="Date" width={120} cell={CellDateTimeSheet} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="TimesheetId" title="Timesheet ID" width={120} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="WorkType" title="Work Type" width={120} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="Hours" title="Hours" width={80} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="JiraIds" title="Jira IDs" width={150} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="Notes" title="Notes" width={100} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="ProjectName" title="Project Name" width={120} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="Version" title="Version" width={80} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="IsApproved" title="Approved" width={100} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="ApprovedBy" title="Approved By" width={120} />
											)}
											{!context.mediaCategory.sm && (
												<Column field="IsPS" title="Is PS" width={60} />
											)}
										</Grid>
									</div>
								</CardBody>
							</Card>
						</div>

						<div>
							{visible && (
								<Dialog
									className="k-dialog-md dialog-timesheets"
									title={editMode ? "Edit Log Time" : "Add Log Time"}
									onClose={handleAddEditClickClose}
								>
									<Form
									// onSubmit={submitHandler}
									>
										{/* <FormInput
											label={isAlertIdValid == true ? "Alert ID* " : <Text color="red" content="Alert ID *Required" />}
											id="alertId-inline"
											fluid
											disabled={isAlertIdDisabled}
											value={timesheetData?.alertId}
											onKeyPress={(event) => {
												if (!/[0-9]/.test(event.key)) {
													event.preventDefault();
												}
											}}
											onChange={(value) => {
												if (value.target.value && value.target.value.length > 0) {
													SetisAlertIdValid(true);
												}
												else {
													SetisAlertIdValid(false);
												}
												settimesheetData({
													...timesheetData,
													alertId: value.target.value,
												})
											}
											}
										/> */}
										{/* <Text color="red" content="*Required" /> */}

										<div className="d-flex" style={{ marginBottom: "0" }} >
											<Text content="Date*" size="medium" weight="regular" style={{ marginBottom: "5px" }} />
											{
												logHoursError ?

													<Text className="text-danger" style={{ marginBottom: "0", marginLeft: '5px' }}  >You cannot log more than 9 hrs in one day</Text>

													: ""
											}
										</div>

										<div className="datepicker-timesheet">
											<Datepicker allowManualInput={false} width={400}
												selectedDate={timesheetData?.Date}
												restrictedDates={restrictDates}

												onDateChange={(e, data) => {
													// setSelectedStartDate(data.value);
													settimesheetData({
														...timesheetData,
														Date: data.value,
													})
												}} />
										</div>


										{/* <Text content="Hours*" size="medium" weight="regular" style={{ marginBottom: "5px" }} /> */}

										<FormDropdown
											label={isHoursValid == true ? "Hours* " : <Text color="red" content="Hours *Required" />}
											items={hoursItems?.map((obj) => {
												return {
													header: obj,
													value: obj,
												};
											})}
											aria-labelledby={"hours-inline"}
											placeholder="Select Hours"
											autosize="true"
											value={timesheetData?.Hours}

											onChange={handleHoursChange}
										/>

										{/* <TimePicker format="HH" steps={steps} defaultValue={defaultHours}  width={180}
											value={timesheetData?.Hours} 
											onChange={(value) => {
												settimesheetData({
													...timesheetData,
													Hours: value.target.value,
												})
											}
											} /> */}

										<FormDropdown
											label={isWorkTypeValid == true ? "Work Type* " : <Text color="red" content="Work Type *Required" />}
											items={workTypes?.map((obj) => {
												return {
													header: obj.WorkTypeName,
													value: obj.WorkTypeId,
												};
											})}
											aria-labelledby={"worktype-inline"}
											placeholder="Select Work Type"
											autosize="true"
											value={timesheetData?.WorkType}
											onChange={handleWorkTypeChange}
											allowFreeform
										/>

										<FormDropdown
											label={isProjectValid == true ? "Product* " : <Text color="red" content="Product *Required" />}
											items={projects?.map((obj) => {
												return {
													header: obj.ProjectName,
													value: obj.ProjectId,
												};
											})}
											aria-labelledby={"project-inline"}
											placeholder="Select Product"
											autosize="true"
											value={timesheetData?.Project}
											onChange={handleProjectChange}
										/>

										<FormInput
											label={isVersionValid == true ? "Version*" : <> <Text content="Version " color="red" /> <Text color="red" content=" -Please provide input as x.x.x.x E.g. - 4.2.0.0" /> </>}
											name="version" id="version-inline" fluid value={timesheetData?.Version}
											placeholder="x.x.x.x E.g. - 4.2.0.0"
											onChange={(value) => {
												if (isVersionValid == false) {
													if ((value.target.value && versionRegex.test(value.target.value) == true)) {
														SetisVersionValid(true);
													}
												}

												settimesheetData({
													...timesheetData,
													Version: value.target.value.trim(),
												})
											}
											}
										/>

										<FormInput
											label="Jira Ids"
											name="JiraIds"
											id="jiraIds-inline"
											fluid
											value={timesheetData?.JiraIds}
											onChange={(value) =>
												settimesheetData({
													...timesheetData,
													JiraIds: value.target.value,
												})
											}
										/>

										<FormInput className="notes-input" label="Notes" name="notes" id="notes-inline" fluid
											value={timesheetData?.Notes}
											onChange={(value) =>
												settimesheetData({
													...timesheetData,
													Notes: value.target.value,
												})
											}
										/>



										<DialogActionsBar>
											<button
												className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary submit-timesheet"
												onClick={addEditSubmitClickHandler}
											// disabled={isValid === false}
											>
												{" "}
												Submit
											</button>
											<button
												className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
												onClick={handleAddEditClickClose}
											>
												{" "}
												Close
											</button>
										</DialogActionsBar>


									</Form>

								</Dialog>
							)}
						</div>
						{/* CSV Upload Dialog */}
						{csvDialogVisible && (
							<Dialog
								className="k-dialog-md dialog-timesheets"
								title="Upload CSV"
								onClose={handleCloseCsvDialog}
							>
								<Form>
									{/* Date Pickers for Start and End Dates */}
									<div style={{ marginBottom: "10px" }}>
										<Text content="Start Date:" weight="bold" />
										<Datepicker
											allowManualInput={false}
											value={startDate}
											onDateChange={(e, data) => setStartDate(data.value)}
											format="yyyy-MM-dd"
										/>
										{startDateError && <Text className="error-message" color="red" content={startDateError} />}
									</div>
									<div style={{ marginBottom: "10px" }}>
										<Text content="End Date:" weight="bold" />
										<Datepicker
											allowManualInput={false}
											value={endDate}
											onDateChange={(e, data) => setEndDate(data.value)}
											format="yyyy-MM-dd"
										/>
										{endDateError && <Text className="error-message" color="red" content={endDateError} />}
									</div>
									{/* File Input for Selecting CSV */}
									<FormInput
										type="file"
										accept=".csv"
										label="Select CSV File"
										onChange={handleFileChange}
									/>
									{csvFileError && <Text className="error-message" color="red" content={csvFileError} />}

									{/* Sync Message Display */}
									{syncMessage && (
										<div style={{ marginTop: '10px' }}>
											<p style={{ color: syncMessageType === "Error" ? 'red' : syncMessageType === "Success" ? 'green' : 'black' }}>
												{syncMessage}
											</p>
										</div>
									)}

									{/* Action Buttons */}
									<DialogActionsBar>
										<button
											className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
											onClick={handleSubmit}
											disabled={loading || dialogProcessed}
										>
											{loading ? "Processing..." : "Submit"}
										</button>
										<button
											className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
											onClick={handleCloseCsvDialog}
										>
											Close
										</button>
									</DialogActionsBar>
								</Form>
							</Dialog>
						)}

						<FluentDialog
							cancelButton="Cancel"
							confirmButton="Confirm"
							onCancel={() => {
								setDeleteConfirmationDialog(false);
								setDeleteAlertObj(null);
							}}
							onConfirm={() => removeData()}
							open={openDeleteConfirmationDialog}
							backdrop
							header="Action confirmation"
							headerAction={{
								icon: <CloseIcon />,
								title: "Close",
								onClick: () => {
									setDeleteConfirmationDialog(false);
									setDeleteAlertObj(null);
								},
							}}
							content={`Are you sure you want to delete log?`}
						/>
						<div>
							{emptyDataMessage && (
								<div className="popup">
									<p>{emptyDataMessage}</p>
									<button onClick={handlePopupClose}>Close</button>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</WindowContext.Consumer>
	) : (
		<Loader />
	);;
};

export { TimesheetReport };
